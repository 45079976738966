<template>
  <div class="snow-wrapper">
    <div
      v-for="flake in snowflakes"
      :key="flake.id"
      class="snowflake"
      :style="flake.style"
    >
      ❄
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      snowflakes: [],
    };
  },
  mounted() {
    const currentMonth = new Date().getMonth() + 1;
    if ([12, 1, 2].includes(currentMonth)) {
      this.generateSnowflakes(35);
    }
  },
  methods: {
    generateSnowflakes(count) {
      const newSnowflakes = [];
      for (let i = 0; i < count; i++) {
        newSnowflakes.push({
          id: i,
          style: {
            left: `${Math.random() * 100}vw`,
            animationDuration: `${Math.random() * 3 + 10}s`, // 5 to 8 seconds
            animationDelay: `${Math.random() * 5}s`,
            fontSize: `${Math.random() * 12 + 6}px`, // 10px to 30px
          },
        });
      }
      this.snowflakes = newSnowflakes;
    },
  },
};
</script>

<style lang="scss">
.snow-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 9999;
}

/* Snowflake Style */
/* Slower animation for snowflakes */
.snowflake {
  position: absolute;
  top: -10px;
  color: rgb(0, 179, 250);
  animation: fall linear infinite;
  opacity: 0.8;
}

/* Slower falling animation with oscillation */
@keyframes fall {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, 25vh); /* Slight right */
  }
  50% {
    transform: translate(-10px, 50vh); /* Slight left */
  }
  75% {
    transform: translate(5px, 75vh); /* Slight right */
  }
  100% {
    transform: translate(-5px, 110vh); /* Slight left */
  }
}
</style>
