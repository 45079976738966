<template>
  <div id="adfox_173072488177579567" style="z-index: 1111 !important"></div>
</template>

<script>
export default {
  data() {
    return {
      isDev: process.env.NODE_ENV === "development", // Faqat development muhitni aniqlash
    };
  },
  mounted() {
    if (!this.isDev) {
      // Yandex reklama skriptini yuklash
      const script = document.createElement("script");
      script.src = "https://yandex.ru/ads/system/context.js";
      script.async = true;
      script.onload = () => {
        console.log("Yandex script loaded");

        // YaContextCb mavjud bo'lsa, Adfox yaratish
        if (window.yaContextCb) {
          window.yaContextCb.push(() => {
            Ya.adfoxCode.createAdaptive(
              {
                ownerId: 11637381,
                containerId: "adfox_173072488177579567",
                params: {
                  p1: "detrs",
                  p2: "jfzz",
                },
              },
              ["desktop", "tablet", "phone"],
              {
                tabletWidth: 830,
                phoneWidth: 480,
                isAutoReloads: false,
              }
            );
          });
        }
      };

      // Skriptni bodyga qo'shish
      document.body.appendChild(script);
    }
  },
};
</script>
